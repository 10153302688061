.header-items-selected .ant-menu-item-selected, .ant-menu-item:hover {
  color: 'lightGrey';
}

.header-items .ant-menu-overflow-item .ant-menu-item .ant-menu-item-only-child .header-items .a {
  color: #7F70EC!important;
}

.testing-badge {
  position: fixed;
  background-color: rgb(240, 77, 13);
  color: white;
  transform: rotateZ(-45deg);
  width: 200px;
  height: 40px;
  line-height: 40px;
  z-index: 100;
  top: 15px;
  left: -60px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, .3);
}

.nav-link, .nav-sub-menu, .ant-menu-title-content {
  color: #5847B2 !important;
}

.nav-item.nav-sub-item:hover {
  background-color: #EEE;
  font-weight: bold;
}

.drag-shadow {
  box-shadow: 0px 5px 10px rgba(36, 6, 167, 0.3);
}

.drag-column {
  background-color: rgb(239, 239, 243);
}
