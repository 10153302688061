@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .ant-btn.ant-btn-default {
  background: rgb(0, 159, 227);
  background: linear-gradient(225deg, rgba(0, 159, 227, 1) 0%, rgba(230, 0, 126, 1) 50%, rgba(245, 156, 26, 1) 100%);
  border: 1px solid white;
  color:white;
} */

.ant-table-xs .ant-table{
  font-size: 12px;
}

.ant-table-xs .ant-table td {
  padding: 4px 4px;
}

.ant-card.ant-card-bordered .ant-card-body{
  padding: 8px;
}

.ant-upload.ant-upload-drag {
  border: 0;
  background-color: white;
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover .employee-drag-notification{
  display: block;
}

.ant-upload.ant-upload-drag .employee-drag-content {
  width: 100%;
} 
.ant-upload.ant-upload-drag.ant-upload-drag-hover .employee-drag-content {
  opacity: 0.3;
  min-height: 100px;
}

.ant-upload.ant-upload-drag .employee-drag-notification {
  display: none;
  width: 100%;
}

.role-transfer-list .ant-transfer-list {
  text-align: left;
  width: 300px;
}